const baseUrl = "api/nodeapp/leads";

const getHeaders = () => {
    const token = localStorage.getItem("token");
  
    return {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    };
  };


// Fetch all leads of the user
export const fetchAllLeads = async () => {
    const url = `${baseUrl}/myLeads`;
  
    try {
        const response = await fetch(url, { method: "GET", headers: getHeaders()});
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching leads:", error);
        throw error;
    }
  };
  
  // Pick Lead for a user.
  export const pickTransaction = async (transactionId) => {
  
    try {
      const response = await fetch(baseUrl, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({ transactionId }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        return { status: false, error: errorData.error };
      }
  
      const data = await response.json();

      return { status: true, data };
    } catch (error) {
      return { status: false, error: error.message };
    }
  };
  
  
  // Update the status, notes, and reminderDate of a transaction
  export const updateTransaction = async (id, { status, notes, reminderDate }) => {
    const url = `${baseUrl}/${id}`;
  
    try {
        const response = await fetch(url, {
            method: "PUT",
            headers: getHeaders(),
            body: JSON.stringify({ status, notes, reminderDate }),
        });
  
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        return data;
    } catch (error) {
      return {status: false, error: error};
    }
  };
  
  // Get transactions with reminders for the logged-in user
  export const fetchReminderTransactions = async () => {
    const url = `${baseUrl}/reminder`;
  
    try {
        const response = await fetch(url, { method: "GET", headers: getHeaders()});
  
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching reminder transactions:", error);
        throw error;
    }
  };
  
  // Get transactions with reminders for the logged-in user
  export const getTransactionsById = async (transactionId) => {
    const url = `${baseUrl}/${transactionId}`;
  
    try {
        const response = await fetch(url, { method: "GET", headers: getHeaders()});
  
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching reminder transactions:", error);
        throw error;
    }
  };
  
  export const deleteUserTransactions = async (transactionId) => {
    const url = `${baseUrl}/${transactionId}`;
  
    try {
        const response = await fetch(url, { method: "DELETE", headers: getHeaders()});
  
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
  
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error fetching reminder transactions:", error);
        throw error;
    }
  };