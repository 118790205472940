const baseUrlPayments = "api/nodeapp/payments";

const getHeaders = () => {
  const token = localStorage.getItem("token");

  return {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  };
};

export const fetchAllPendingPayments = async (
  status = "pending",
  fromDate,
  toDate
) => {
  const url = `${baseUrlPayments}?status=${status}&fromDate=${fromDate}&toDate=${toDate}`;

  try {
    const response = await fetch(url, { method: "GET" });
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    const data = await response.json();
    return { status: true, data: data };
  } catch (error) {
    console.error("Error fetching payments:", error);
    throw error;
  }
};

// // Fetch all leads of the user
// export const fetchAllLeads = async () => {
//   const url = `${baseUrlPayments}/leads`;

//   try {
//       const response = await fetch(url, { method: "GET", headers: getHeaders()});
//       if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//       }
//       const data = await response.json();
//       return data;
//   } catch (error) {
//       console.error("Error fetching leads:", error);
//       throw error;
//   }
// };

// // Pick a transaction for the logged-in user
// export const pickTransaction = async (transId) => {
//   const url = `${baseUrlPayments}/pick/${transId}`;

//   try {
//       const response = await fetch(url, {
//           method: "POST",
//           headers: getHeaders(),
//       });

//       if (response.status === 400) {
//           return {status: false, error: data.error}
//       }

//       const data = await response.json();
//       return data;
//   } catch (error) {
//       return {status: false, error: error}
//   }
// };

// // Update the status, notes, and reminderDate of a transaction
// export const updateTransaction = async (id, { status, notes, reminderDate }) => {
//   const url = `${baseUrlPayments}/${id}`;

//   try {
//       const response = await fetch(url, {
//           method: "PUT",
//           headers: getHeaders(),
//           body: JSON.stringify({ status, notes, reminderDate }),
//       });

//       if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();
//       return data;
//   } catch (error) {
//     return {status: false, error: error};
//   }
// };

// // Get transactions with reminders for the logged-in user
// export const fetchReminderTransactions = async () => {
//   const url = `${baseUrlPayments}/reminder`;

//   try {
//       const response = await fetch(url, { method: "GET", headers: getHeaders()});

//       if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();
//       return data;
//   } catch (error) {
//       console.error("Error fetching reminder transactions:", error);
//       throw error;
//   }
// };

// // Get transactions with reminders for the logged-in user
// export const getTransactionsById = async (transactionId) => {
//   const url = `${baseUrlPayments}/${transactionId}`;

//   try {
//       const response = await fetch(url, { method: "GET", headers: getHeaders()});

//       if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();
//       return data;
//   } catch (error) {
//       console.error("Error fetching reminder transactions:", error);
//       throw error;
//   }
// };

// export const deleteUserTransactions = async (transactionId) => {
//   const url = `${baseUrlPayments}/remove/${transactionId}`;

//   try {
//       const response = await fetch(url, { method: "DELETE", headers: getHeaders()});

//       if (!response.ok) {
//           throw new Error(`HTTP error! Status: ${response.status}`);
//       }

//       const data = await response.json();
//       return data;
//   } catch (error) {
//       console.error("Error fetching reminder transactions:", error);
//       throw error;
//   }
// };
