import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Paper,
} from "@mui/material";

import { pickTransaction } from "../api/leads";
import {fetchAllPendingPayments} from "../api/payments";
import CommonDialog from "../components/commonDialog";
import CustomSnackbarAlert from "../components/customAlert";
import { useNavigate } from "react-router-dom";

export default function Payments() {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showdialog, setShowDialog] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogContent, setDialogContent] = useState('');
  const [selectedEntry, setSelectedEntry] = useState('');

  const navigate = useNavigate();
  
  // For handeling Alerts.
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");
  const [showAlert, setShowAlet] = useState(false);

  const loadPayments = async () => {
    try {
      const result = await fetchAllPendingPayments();
      if (result.status) {
        setPayments(result.data);
      } else {
        setError(result.error);
      }
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const openDialog = (transactionId) => {
    setSelectedEntry(transactionId);
    setDialogTitle('Pick Lead');
    setDialogContent('Do you want to pick this Lead');
    setShowDialog(true);
  }

  const handleCancel = () => {
    setShowDialog(false);
  };

  const handleCloseSnackbar = () => {
    setShowAlet(false);
  };

  const handleAgree = async () => {
    const data =  await pickTransaction(selectedEntry);
    if(data.status){
      loadPayments();
      setAlertMessage('Lead picked successfully');
      setShowAlet(true);
      navigate(`/lead?id=${data.data._id}`)
    }
    else{
      // setAlertMessage(data.error);
      // setAlertSeverity('error');
      // setShowAlet(true);
    }
    setShowDialog(false);
  };

  useEffect(() => {
    loadPayments();
  }, []);



  if (loading) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography color="error">Error: {error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <>
      <Typography variant="h4" sx={{ marginBottom: 3 }}>
        Pendin Payments
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <strong>Name</strong>
              </TableCell>
              <TableCell>
                <strong>Email</strong>
              </TableCell>
              <TableCell>
                <strong>Phone</strong>
              </TableCell>
              <TableCell>
                <strong>Date</strong>
              </TableCell>
              <TableCell>
                <strong>Amount</strong>
              </TableCell>
              <TableCell>
                <strong>Action</strong>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment, index) => (
              <TableRow key={index}>
                <TableCell>{payment.name}</TableCell>
                <TableCell>{payment.email}</TableCell>
                <TableCell>{payment.phone}</TableCell>
                <TableCell>
                  {new Date(payment.date).toLocaleDateString()}{" "}
                  {new Date(payment.date).toLocaleTimeString()}
                </TableCell>
                <TableCell>{payment.amount.toFixed(2)}</TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => openDialog(payment._id)}
                  >
                    Pick
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <CommonDialog
        open={showdialog}
        title={dialogTitle}
        content={dialogContent}
        onCancel={handleCancel}
        onAgree={handleAgree}
      />
      {/* CustomAlert component */}
      <CustomSnackbarAlert
          message={alertMessage}
          severity={alertSeverity}
          open={showAlert}
          onClose={handleCloseSnackbar}
        />
      </>
    </Box>
  );
}
