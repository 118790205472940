import React from "react";
import { Routes, Route } from "react-router-dom";
import ProtectedRoute from "../routes/ProtectedRoute";

// {Pages imports}
import LoginPage from "../pages/login";
import AdminDashboardPage from "../pages/Dashboard/AdminDashboardPage";
import WhatsNewCardView from "../pages/WhatNew/ViewAll";
import ManagerDashboardPage from "../pages/Dashboard/ManagerDashboardPage";
import UserDashboardPage from "../pages/Dashboard/UserDashboardPage";
import Users from "../pages/Users";
import Payments from "../pages/Payments";
import MyLead from "../pages/MyLeads";
import Lead from "../pages/Lead";
import WhatsNew from "../pages/WhatNew/WhatsNew";

const AppRoutes = () => {
  return (
    <Routes>
      {/* Public Routes */}
      <Route path="/" element={<LoginPage />} />
      <Route path="/login" element={<LoginPage />} />

      {/* Protected Routes */}
      <Route
        path="/adminDashboard"
        // element={<AdminDashboardPage/>}
        element={
          <ProtectedRoute
            element={AdminDashboardPage}
            allowedRoles={["admin"]}
          />
        }
      />
      <Route
        path="/userDashboard"
        // element={<UserDashboardPage/>}
        element={
          <ProtectedRoute
            element={UserDashboardPage}
            allowedRoles={["user"]}
          />
        }
      />
      <Route
        path="/managerDashboard"
        // element={<ManagerDashboardPage/>}
        element={
          <ProtectedRoute
            element={ManagerDashboardPage}
            allowedRoles={["admin", "manager"]}
          />
        }
      />
      <Route
        path="/viewAll"
        // element={<WhatsNewCardView/>}
        element={
          <ProtectedRoute
            element={WhatsNewCardView}
            allowedRoles={["admin","manager"]}
          />
        }
      />
      <Route
        path="/whatsNew"
        element={<WhatsNew/>}
        allowedRoles={["admin","manager"]}
      />
      <Route
        path="/users"
        // element={<Users/>}
        element={
          <ProtectedRoute
            element={Users}
            allowedRoles={["admin"]}
          />
        }
      />
      <Route
        path="/payments"
        element={<Payments/>}
      />
      <Route
        path="/leads"
        element={<MyLead/>}
      />
      <Route
        path="/lead"
        element={<Lead/>}
      />
    </Routes>
  );
};

export default AppRoutes;
