import React, { useState, useEffect } from "react";
import {
  fetchAllLeads
} from "../api/leads";
import {
  Typography,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip,
  Paper,
  Tooltip,
} from "@mui/material";
import NotesIcon from "@mui/icons-material/Notes";
import { useNavigate } from "react-router-dom";

export default function MyLeads() {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [leadsData, setLeadsData] = useState([]);

  const navigate = useNavigate();

  const getMyLeads = async () => {
    setLoading(true);
    try {
      const data = await fetchAllLeads();
      if (data.status) {
        setLeadsData(data.data);
      }
    } catch (error) {
      setError("Failed to load data");
    } finally {
      setLoading(false);
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "pending":
        return "warning"; // Yellow
      case "contacted":
        return "info"; // Blue
      case "follow":
        return "primary"; // Light Blue
      case "completed":
        return "success"; // Green
      case "denied":
        return "error"; // Red
      default:
        return "default"; // Grey
    }
  };

  useEffect(() => {
    getMyLeads();
  }, []);

  if (loading) {
    return <Typography variant="h6">Loading...</Typography>;
  }

  if (error) {
    return (
      <Box sx={{ padding: 3 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 3 }}>
      <>
        <Typography variant="h4" sx={{ marginBottom: 3 }}>
          My Leads
        </Typography>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Name</strong>
                </TableCell>
                <TableCell>
                  <strong>Email</strong>
                </TableCell>
                <TableCell>
                  <strong>Phone</strong>
                </TableCell>
                <TableCell>
                  <strong>Date</strong>
                </TableCell>
                <TableCell>
                  <strong>Note</strong>
                </TableCell>
                <TableCell>
                  <strong>Status</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leadsData.map((entry, index) => (
                <TableRow
                  key={index}
                  onClick={() => navigate(`/lead?id=${entry._id}`)}
                >
                  <TableCell>{entry.customerName}</TableCell>
                  <TableCell>{entry.customerEmail}</TableCell>
                  <TableCell>{entry.customerPhone}</TableCell>
                  <TableCell>
                    {new Date(entry.paymentDate).toLocaleDateString()}{" "}
                    {new Date(entry.paymentDate).toLocaleTimeString()}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={entry.notes ?? "Nothing"} arrow>
                      <NotesIcon />
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={
                        entry.status.charAt(0).toUpperCase() +
                        entry.status.slice(1)
                      }
                      color={getStatusColor(entry.status)}
                      // variant="outlined"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </>
    </Box>
  );
}
